<script setup>

</script>

<template>
  <div class="designs-content">
    <i>D E S I G N</i>
<!--    <div class="imgs">-->
<!--      <img src="../assets/imgs/1.jpg" alt="">-->
<!--      <img src="../assets/imgs/2.jpg" alt="">-->
<!--    </div>-->
  </div>

</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200&display=swap');
.designs-content {
  padding: 0 10%;
  position: relative;

  i {
    font-size: 3em;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 200;
  }

  .imgs  {
    display: flex;
    justify-content: space-between;

    img:first-child {
      width: 70%;
    }

    img:nth-child(2) {
      width: 17.5%;
    }
  }
}
</style>