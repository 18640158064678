<template>
  <div class="homepage">
    <title-background class="home-title">
      <title-content/>
    </title-background>
    <designs-content/>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import TitleBackground from "@/components/TitleBackground.vue";
import TitleContent from "@/components/HomeTitleContent.vue";
import DesignsContent from "@/components/DesignsContent.vue";

export default defineComponent({
  components: {DesignsContent, TitleContent, TitleBackground}
})
</script>

<style lang="scss">
.homepage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.home-title {
  width: 100%;
  height: 100vh;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
