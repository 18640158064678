<script setup>
import versionJSON from "../utils/versionJSON.json";
import moment from "moment";

// eslint-disable-next-line no-unused-vars
let compileTime = moment(versionJSON.compileTime).format('YYYY/MM/DD hh:mm')
</script>

<template>
  <div class="web-footer">
    <div class="platform">
      <i>Powered by</i>
      <a href="https://cloudflare.com/" class="cloudflare"><img src="../assets/cloudflare.svg" alt=""></a>
      <a href="https://pages.github.com/" class="github"><img src="../assets/githubpages.svg" alt=""></a>
      <a href="https://fonts.google.com/" class="google-fonts"><img src="../assets/gfonts.svg" alt=""></a>
    </div>
    <div class="logo">
      <i>Author</i>
      <img src="../assets/logo.svg" alt="">
    </div>
    <i class="info">This website was successfully built on {{ compileTime }} by 0x22D8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;桂ICP懒得备案</i>
  </div>
</template>

<style scoped lang="scss">
  .web-footer {
    position: relative;
    height: 300px;
    width: 100%;
    background-color: #414142;
    padding: 10px 5%;
    color: #7d7f80;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .platform {
      height: 200px;
      //width: 120px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;

      i {
        font-size: 1.2em;
        font-weight: 700;
        color: #7d7f80;
      }

      .cloudflare,.cloudflare img {
        width: 200px;
      }
      .github,.github img {
        height: 40px;
      }
      .google-fonts,.google-fonts img {
        height: 28.5px;
        //transform: translateY(-5px);
      }
    }

    .logo {
      display: block;
      position: absolute;
      bottom: 5px;
      right: 5%;
      width: 140px;

      i {
        font-size: 1.1em;
        font-weight: 700;
        color: #7d7f80;
        margin-bottom: 5px;
        display: block;
      }
    }

    .info {
      position: absolute;
      bottom: 0;
      color: #7d7f80;

    }
  }
</style>