<script setup>
import {ref} from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  color: {
    type:String,
    default:'#3d4da2 48deg, #45acbf 85deg, #791088 345deg'
  }
})

let c = ref(" conic-gradient(" + props.color + ")")

// console.log(c.value)
</script>

<template>
  <div class="title-background">
    <i class="gradient"></i>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.title-background {
  background-color: #414142;
  overflow: hidden;
  position: relative;
}

.gradient {
  display: block;
  width: 100%;
  height: 100%;
  background: v-bind(c);
  filter: blur(120px);
  z-index: 1;
  animation-name: rotate;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  @keyframes rotate {
    0% {
      transform: rotate(0) scale(4);
    }
    100% {
      transform: rotate(360deg) scale(4);
    }
  }
}

:slotted(*) {
  position: relative;
  z-index: 2;
}
</style>