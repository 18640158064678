<script setup>
import {onMounted, ref} from 'vue'


let lineWidth = ref('60%')
let lineHeight = ref('2px')
let lineBGC = ref('#f8f9fa')

function whenScroll() {
  if (scrollY > 5) {
    lineWidth.value = '100%'
    lineHeight.value = '67px'
    lineBGC.value = '#454748'
  } else {
    lineWidth.value = '60%'
    lineHeight.value= '2px'
    lineBGC.value = '#f8f9fa'
  }
}

onMounted(() => {
  // whenScroll()
  addEventListener('scroll',whenScroll)
})
// addEventListener('scroll',lineScroll)
</script>

<!--<script>-->
<!--export default {-->
<!--  components: -->
<!--}-->
<!--</script>-->

<template>
  <div class="head-nav">
    <nav>
      <a href="https://0x22d8.one" class="logo">
        <img src="../assets/logo.svg" alt="logo">
      </a>
      <div class="sub">
<!--        <a href="#">Home</a>-->
<!--        <a href="#">Project</a>-->
<!--        <a href="#">About</a>-->
        <slot></slot>
      </div>
      <div class="website">
        <a href="https://space.bilibili.com/245753840">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M488.6 104.1C505.3 122.2 513 143.8 511.9 169.8V372.2C511.5 398.6 502.7 420.3 485.4 437.3C468.2 454.3 446.3 463.2 419.9 464H92C65.6 463.2 43.8 454.2 26.7 436.8C9.7 419.4 .8 396.5 0 368.2V169.8C.8 143.8 9.7 122.2 26.7 104.1C43.8 87.8 65.6 78.8 92 78H121.4L96.1 52.2C90.3 46.5 87.4 39.2 87.4 30.4C87.4 21.6 90.3 14.3 96.1 8.6C101.8 2.9 109.1 0 117.9 0C126.7 0 134 2.9 139.8 8.6L213.1 78H301.1L375.6 8.6C381.7 2.9 389.2 0 398 0C406.8 0 414.1 2.9 419.9 8.6C425.6 14.3 428.5 21.6 428.5 30.4C428.5 39.2 425.6 46.5 419.9 52.2L394.6 78L423.9 78C450.3 78.8 471.9 87.8 488.6 104.1H488.6zM449.8 173.8C449.4 164.2 446.1 156.4 439.1 150.3C433.9 144.2 425.1 140.9 416.4 140.5H96.1C86.5 140.9 78.6 144.2 72.5 150.3C66.3 156.4 63.1 164.2 62.7 173.8V368.2C62.7 377.4 66 385.2 72.5 391.7C79 398.2 86.9 401.5 96.1 401.5H416.4C425.6 401.5 433.4 398.2 439.7 391.7C446 385.2 449.4 377.4 449.8 368.2L449.8 173.8zM185.5 216.5C191.8 222.8 195.2 230.6 195.6 239.7V273C195.2 282.2 191.9 289.9 185.8 296.2C179.6 302.5 171.8 305.7 162.2 305.7C152.6 305.7 144.7 302.5 138.6 296.2C132.5 289.9 129.2 282.2 128.8 273V239.7C129.2 230.6 132.6 222.8 138.9 216.5C145.2 210.2 152.1 206.9 162.2 206.5C171.4 206.9 179.2 210.2 185.5 216.5H185.5zM377 216.5C383.3 222.8 386.7 230.6 387.1 239.7V273C386.7 282.2 383.4 289.9 377.3 296.2C371.2 302.5 363.3 305.7 353.7 305.7C344.1 305.7 336.3 302.5 330.1 296.2C323.1 289.9 320.7 282.2 320.4 273V239.7C320.7 230.6 324.1 222.8 330.4 216.5C336.7 210.2 344.5 206.9 353.7 206.5C362.9 206.9 370.7 210.2 377 216.5H377z"/></svg></a>
        <a href="https://x.com/0x22D8">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></a>
      </div>
    </nav>
    <i></i>
  </div>
</template>

<style scoped lang="scss">
.head-nav {
  z-index: 1000;
  height: 65px;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5px;

  transition: all 0.2s ease-in-out;

  nav {
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;

    .logo {
      height: 50px;

      img {
        height: 50px;
      }
    }

    .sub {
      display: flex;
      width: 65%;
      justify-content: flex-start;
      align-items: center;
      gap: 3rem;

      :slotted(a) {
        font-weight: 700;
      }
    }

    .website {
      width: 10%;
      display: flex;
      align-items: center;
      gap: 20px;

      a {
        width: 1.4rem;
        height: 1.4rem;
      }

      svg {
        fill: #f8f9fa;
        width: 100%;
      }
    }
  }
}

.head-nav i {
  display: block;
  width: v-bind(lineWidth);
  height: v-bind(lineHeight);
  background-color: v-bind(lineBGC);
  position: absolute;
  bottom: -2px;
  z-index: 998;

  transition: all 0.2s ease-in-out;
}

:slotted(a),a {
  color: #f8f9fa;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  font-size: 1.2rem;
  display: block;
}

:slotted(a):hover,a:hover {
  filter: drop-shadow(0 0 2px rgb(255 255 255));
  letter-spacing: 0.5px;
}

</style>