<template>
  <HeadNav>
    <router-link to="/">Home</router-link>
<!--    <router-link to="/">Project</router-link>-->
    <router-link to="/about">About</router-link>
  </HeadNav>
  <router-view v-slot="{ Component }">
    <transition name="ContentView">
      <component :is="Component" />
    </transition>
  </router-view>
<!--  <router-view/>-->
  <div class="asdasd"></div>
  <web-footer/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #f8f9fa;
  letter-spacing: -0.02em;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: #131314;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  width: 100%;
  background-color: #414142;
}

::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #aaaaad;
  border-radius: 4px;
}

.ContentView-enter-active,
.ContentView-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.ContentView-enter-from,
.ContentView-leave-to {
  display: none;
  opacity: 0;
}

.asdasd {
  height: 60vh;
}
</style>

<script>
import HeadNav from "@/components/HeadNav.vue";
import {defineComponent} from "vue";
import WebFooter from "@/components/WebFooter.vue";
export default defineComponent( {
  components: {WebFooter, HeadNav}
})

</script>